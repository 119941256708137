
<v-container fluid>
    <v-card style="width: 100%">
        <v-card-text>
            <v-item-group
                class="flex row ml-6 mt-1"
                flex
                style="align-content: space-between">
                <v-autocomplete
                    v-model="selectedClass"
                    :items="classes"
                    :return-object="true"
                    class="mr-2"
                    clearable
                    item-text="full_name"
                    item-value="IDFaecher"
                    label="Klasse"
                    no-data-text="Loading..."
                    prepend-inner-icon="mdi-magnify"
                    style="max-width: 200px"/>
                <v-autocomplete
                    v-model="selectedSubject"
                    :disabled="!subjects.length"
                    :item-text="item => `${item.FachName} (${item.fachkuerzel})`"
                    :items="subjects"
                    :return-object="true"
                    class="mr-2"
                    clearable
                    item-value="IDFaecher"
                    label="Fächer"
                    no-data-text="Loading..."
                    prepend-inner-icon="mdi-magnify"
                    style="max-width: 200px"/>
                <v-btn-toggle
                    v-model="selectedType"
                    borderless
                    class="ml-6"
                    color="red">
                    <v-btn value="s">Stunden</v-btn>
                    <v-btn value="b">Blöcke</v-btn>
                </v-btn-toggle>
                <v-autocomplete
                    v-model="selectedBlock"
                    :disabled="!blocks.length"
                    :items="filtertBlocks"
                    :label="dropLable"
                    :return-object="true"
                    class="ml-6 mr-2"
                    clearable
                    item-text="text"
                    item-value="value"
                    no-data-text="Loading..."
                    prepend-inner-icon="mdi-magnify"
                    style="max-width: 200px"/>
                <v-btn
                    class="ml-6"
                    x-large
                    @click="openAbsentClass">
                    Klassenbuch öffnen
                </v-btn>
            </v-item-group>
        </v-card-text>
    </v-card>
</v-container>
