import {NavigationGuardNext, Route} from 'vue-router/types/router';

const weekendGuard = (to: Route, from: Route, next: NavigationGuardNext): any => {
    const today = new Date();
    const day = today.getDay();

    if (day === 0 || day === 6) {
        swal("Wochenende", "Die Anwesenheiten können nicht am Wochenende eingetragen werden.", "warning");
        next({name: 'dashboard'});
    } else {
        next();
    }
};

export default weekendGuard;
