import Vue from "vue";
import Router, {RouteConfig} from "vue-router";
import multiguard from "vue-router-multiguard";
import authGuard from "@/core/middleware/auth-guard";
import authSignedGuard from "@/core/middleware/auth-signed-guard";
import permissionsGuard from "@/core/middleware/permissions-guard";
import Login from "@/pages/login.vue";
import Logout from "@/pages/logout.vue";
import Dashboard from "@/pages/dashboard.vue";
import Timetable from "@/pages/timetable.vue";
import Contents from "@/pages/contents.vue";
import Absents from "@/pages/absents.vue";
import AbsentsClass from "@/pages/absents-class.vue";
import Homework from "@/pages/homework.vue";
import Documents from "@/pages/documents.vue";
import Grades from "@/pages/grades.vue";
import HomeworkCreate from "@/pages/homeworkCreate.vue";
import AbsentsChange from "@/pages/absents-change.vue";
import PageNotFound from "@/pages/[...all].vue";
import GradeWorkSocial from "@/pages/gradeWorkSocial.vue";
import GradesExam from "@/pages/gradesExams.vue";
import SubGrades from "@/pages/subGrades.vue";
import validSchedule from "@/core/middleware/valid-schedule";
import weekendGuard from "@/core/middleware/weekend-guard";

Vue.use(Router);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'login',
        component: Login,
        beforeEnter: multiguard([authSignedGuard]),
        meta: {
            layout: 'auth'
        }
    },
    {
        name: 'logout',
        path: '/logout',
        component: Logout,
        beforeEnter: multiguard([authGuard]),
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        beforeEnter: multiguard([authGuard])
    },
    {
        path: '/timetable',
        name: 'timetable',
        component: Timetable,
        beforeEnter: multiguard([authGuard])
    },
    {
        path: '/contents',
        name: 'contents',
        component: Contents,
        beforeEnter: multiguard([authGuard])
    },
    {
        path: '/absents',
        name: 'absents',
        component: Absents,
        beforeEnter: multiguard([authGuard])
    },
    {
        path: '/absents/class/:classId/:block/:subjectId',
        name: 'absents.class',
        component: AbsentsClass,
        beforeEnter: multiguard([authGuard, validSchedule])
    },
    {
        path: '/absent/change',
        name: 'absents.change',
        component: AbsentsChange,
        beforeEnter: multiguard([authGuard, weekendGuard])
    },
    {
        path: '/homework',
        name: 'homework',
        component: Homework,
        beforeEnter: multiguard([authGuard])
    },
    {
        path: '/homework-create',
        name: 'homework-create',
        component: HomeworkCreate,
        beforeEnter: multiguard([authGuard]),
        props: route => {
            return {
                selectedClassId: route.query.selectedClassId,
                selectedSubjectId: route.query.selectedSubjectId,
            }
        }
    },
    {
        path: '/documents',
        name: 'documents',
        component: Documents,
        beforeEnter: multiguard([authGuard])
    },
    {
        path: '/grades',
        name: 'grades',
        component: Grades,
        beforeEnter: multiguard([authGuard])
    },
    {
        path: '/gradesWorkSocial',
        name: 'gradesWorkSocial',
        component: GradeWorkSocial,
        beforeEnter: multiguard([authGuard])
    },
    {
        path: '/gradesExam',
        name: 'gradesExam',
        component: GradesExam,
        beforeEnter: multiguard([authGuard])
    },
    {
        path: '/subGrades',
        name: 'subGrades',
        component: SubGrades,
        beforeEnter: multiguard([authGuard])
    },
    {
        path: '*',
        component: PageNotFound,
        meta: {
            title: '404 Not Found'
        }
    }
];

const createRouter = () => new Router({
    mode: 'history',
    routes
})

const router = createRouter()

router.beforeEach(multiguard([permissionsGuard]))

export default router;
